import Vue from 'vue'
import App from './App.vue'
import './assets/todo-mvc.css'
//通过export{}导出，直接 变量/函数名解构方式接收！！
import { saveData, getData } from './utils/localStorage.js'

// 将 saveData 和 getData 方法添加到 Vue 的原型上
Vue.prototype.$saveData = saveData;
Vue.prototype.$getData = getData;

Vue.config.productionTip = false;


new Vue({
  render: h => h(App),
}).$mount('#app')
