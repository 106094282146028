/*
// default对象形式 导出，在另一个js文件中import时，要以 对象obj形式 接收。通过“obj.方法名”分别调用。
export default {
    saveData: function (value) {
        if (value) {
            localStorage.setItem('todoMVC', JSON.stringify(value));
        }
    },
    getData() {//可直接简写！！！当前对象的键名，和函数名getData同名。
        let data = null;
        try {
            if (localStorage.getItem('todoMVC')) {
                data = JSON.parse(localStorage.getItem('todoMVC'));
                return data;
            } else {
                console.log(111);
                data = {
                    optionCur: 'all',
                    dataList: [
                        { text: '吃点什么呢？？？？', status: false, id: 0 },
                        { text: '人嘛！总得有点追求.....', status: false, id: 2 },
                        { text: '时不我待', status: true, id: 3 },
                    ]
                }
                localStorage.setItem('todoMVC', JSON.stringify(data));
                return data;
            }
        } catch (error) {
            console.warn(error);
        }
    }
} */



//通过export关键字， 将变量名、或函数名用一个{}导出，要以{}解构方式接收，函数可直接调用。
function saveData(value) {
    if (value) {
        // 当value不为空时才执行本地存储，避免 因之前有key，值为空，无法加载 假数据
        localStorage.setItem('todoMVC', JSON.stringify(value));
    }
}
function getData() {
    try {
        const db = localStorage.getItem('todoMVC');
        if (!db) {
            // 假数据
            const data = {
                optionCur: 'all',
                dataList: [
                    { text: '吃点什么呢？？？？', status: false, id: 0 },
                    { text: '人嘛！总得有点追求.....', status: false, id: 2 },
                    { text: '时不我待', status: true, id: 3 },
                ]
            }
            localStorage.setItem('todoMVC', JSON.stringify(data));
            return JSON.parse(db);
        }
        else {
            return JSON.parse(db);
        }
    }
    catch (error) {
        // 加载本地数据出错
        console.error('Error while loading data from localStorage:', error);
    }
}
export { saveData, getData };


