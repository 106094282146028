<template>
  <input type="text" placeholder="回 车 保 存" class="edit" style="display: 'none'"
  @keyup.enter="outEditing($event.target.value.trim(),targetItem)"
  @blur="blurHandler">
</template>

<script>
export default {
  name: 'TodoInput',
  data() {
    return {
    }
  },
  	props: {
    outEditing: Function,
    targetItem: String,
  },
  methods: {
    blurHandler($event) {
      let val = $event.target.value.trim();
      this.outEditing(val, this.targetItem);
      $event.target.value = '';
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
