<template>
	<section class="main" style="">
		<ul class="todo-list">
			<li 
			v-for="(item,index) in filterDataList"
			:key="item.id"
			:class="['todo', { editing: editFlag==index },{ completed :item.status}]"
			@dblclick="inEditing(index)">
			
				<div class="view">
					<input type="checkbox" class="toggle" 
					:checked="item.status"
					@click="checkItemF(item.id)"> 

					<label>{{ item.text }}</label> 

					<button class="destroy"
					@click="deleteItem(item.id)"></button>
				</div> 

				<TodoInput
					:targetItem="item.text"
					:outEditing="outEditing"
					ref="item"
				></TodoInput>
				<!-- <input type="text" class="edit" style="display: none;"> -->
			</li>
		</ul>
	</section> 
</template>

<script>
import TodoInput from '@/components/TodoTools/TodoInput.vue'

export default {
	name: 'TodoMain',
	data() {
		return {
			editFlag: -1,
		}
	},
	props: {
		filterDataList: Array,
		deleteItem: Function,
		updateItem: Function,
		checkItem: Function,
	},
	components: {
		TodoInput
	},
	methods: {
		checkItemF(id) {
			this.checkItem(id);
		},
		inEditing(index) {
			// 双击li，通过将 双击当前li的index 赋值给editFlag标志，使editFlag == index，编辑状态的editing类生效，文本框被动态渲染
			this.editFlag = index;

			// DOM 更新是异步的！当 Vue 数据发生变化、或者 动态增删改dom时，Vue 会等待队列中所有的 DOM 更新操作完成，然后才会执行 $nextTick() 方法中的代码。
			this.$nextTick(() => {
				// 通过子组件上的ref获取点击的， 子组件实例this.$refs.item[index]，在通过组件实例上的$el方法得到对应的input，即dom节点，使其focus()聚焦。
				this.$refs.item[index].$el.focus();
			});
		},
		outEditing(val, todoItem) {
			//注意：事件监听最好放在原生dom上，只通过props为子组件传递已定义的方法或自定义
			// 本页的TodoInput子组件退出编辑时，直接调用本方法，会继而调用app组件的updateItem方法
			if (val != '' && (val != todoItem)) {
				//值不为空，且与原值不同，才可 保存更改
				this.updateItem(val, todoItem);
			}
			//退出编辑模式时，编辑框消失
			this.editFlag = -1;
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
