<template>
  <section class="todoapp">
    <TodoHeader
    :addItem="addItem"
    :allSumStaus="allSumStaus"
    :changeSumStaus="changeSumStaus"
    :dataListLength="dataListLength"
    ></TodoHeader>
    
    <TodoMain
    :filterDataList="filterDataList"
    :checkItem="checkItem"
    :updateItem="updateItem"
    :deleteItem="deleteItem"
    ></TodoMain>

    <TodoFooter
    :leftSum="leftSum"
    :clearDone="clearDone"
    :optionCur="optionCur"
    :changeOption="changeOption"
    ></TodoFooter>
  </section>
</template>

<script>
import TodoHeader from './components/TodoHeader.vue'
import TodoMain from './components/TodoMain.vue'
import TodoFooter from './components/TodoFooter.vue'
// export default {}形式导入的文件，导入的是个obj，以此方式接收并“obj.方法()”使用
// import local from '@/utils/localStorage.js'
// export{}解构形式导出的js文件，函数定义、变量名直接被解构导入，可直接“方法()”调用
// import { saveData, getData } from '@/utils/localStorage.js'

export default {
  name: 'App',
  components: {
    TodoHeader,
    TodoMain,
    TodoFooter,
  },
  data() {
    return {
      // 当前组件用到的 数据属性 要提前定义初始化
      optionCur: '',
      dataList:[]
    }
  },
  created() {
    // obj中数据类型复杂，无法直接解构赋值
    let obj = this.$getData();
    this.optionCur =  obj.optionCur;
    this.dataList =  obj.dataList;
  },
  watch: {
      optionCur() {
        console.log("切换选项，就保存");
        this.$saveData({
          optionCur:this.optionCur,
          dataList:this.dataList
        });
    },
    dataList: {
      deep:true,
      handler() {
        console.log("数据一旦改变，就保存");
        this.$saveData({
          optionCur: this.optionCur,
          dataList: this.dataList
        });
      }
    }
   },
  computed: {
    dataListLength() {
      // 无数据时，禁用全选按钮
    return  this.dataList.length>0 ? false : true;
    },
    allSumStaus() {
      //统计 总条目状态：完成所有条目返回true，否则false(当没数据时，全选按钮 不会被选中)
      return this.dataList.length > 0 && this.dataList.every(ele => ele.status);
    },
    leftSum() {
      // 统计 未完成 条目数量(左下角 未完成数量 显示)
      return this.dataList.filter(ele => ele.status == false).length;
    },
    filterDataList() {
      // 根据不同选项，过滤数据
      if (this.optionCur == 'active') {
        console.log(this.optionCur);
        return this.dataList.filter(ele => !ele.status);
      } else if (this.optionCur == 'completed') {
        return this.dataList.filter(ele => ele.status);
      } else if (this.optionCur == 'all') {
        return this.dataList;
      }
    },
  },
  methods: {
    addItem(val) {
      // 添加条目
        if ((this.dataList.findIndex(ele => ele.text == val) != -1)) {
          alert("本事项 已添加，请勿重复添加！");
          return
        } else {
          let obj = {
            text: val,
            status: false,
            id: Date.now()
          }
          this.dataList.push(obj);
        }
    },
    changeSumStaus(status) {
    //  全选框功能：搭配 计算属性allSumStaus，【使 全选 》全不选，部分选或都没选 》全选】
      this.dataList.map(item => item.status = status);
    },
    checkItem(id) {
      // 根据点击条目的id，在数组中找到对应条目，更改其状态
      let target = this.dataList.find(ele => ele.id == id);
      target.status = !target.status;
    },
    updateItem(val, item) {
      if ((this.dataList.findIndex(ele => ele.text == val) != -1)) {
        // 回车退出编辑时，会回车、失焦两次触发updateIte？？？？？
        console.log("本事项 已添加！");
        return
      } else {
        this.dataList.forEach(ele => {
          //在原值所在地更改对应text，不改变id、status
          if (ele.text == item) {
            ele.text = val;
          }
        });
      }
    },
    deleteItem(id) {
      // 根据点击条目的id，在数组中找到对应条目，使用其索引删除
      this.dataList.forEach((ele, ind) => {
        if (id==ele.id) {
          this.dataList.splice(ind,1);
        }
      });
    },
    clearDone() {
      // 只保留 未完成 状态 条目
     this.dataList =  this.dataList.filter(ele=> !ele.status);
    },
    changeOption(str) {
      // 更改选项，切换对应数据
      this.optionCur = str;
    }
  }
}
</script>

<style lang="less">
</style>
