<template>
	<footer class="footer" style="">
		<span class="todo-count"><strong>{{ leftSum }}</strong> items left</span> 
		<ul class="filters">
			<li><a href="#" :class="{selected:optionCur=='all'}"
				@click="changeOption('all')">all</a></li>
			<li><a href="#" :class="{ selected: optionCur == 'active' }"
				@click="changeOption('active')">active</a></li>
			<li><a href="#" :class="{ selected: optionCur == 'completed' }"
				@click="changeOption('completed')">completed</a></li>
		</ul> 
		<button class="clear-completed"
		@click="clearDone">clear completed</button>
	</footer>
</template>

<script>
export default {
  	name: 'TodoFooter',
	props: ['leftSum', 'todos', 'clearDone', 'optionCur', 'changeOption']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
