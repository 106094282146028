<template>
  		<header class="header">
  			<h1>todos</h1> 
  			<input type="text" placeholder="what need to be done?" class="new-todo"
        @keyup.enter="addItemF">

        <input type="checkbox" class="toggle-all"
        :disabled="dataListLength"
        :checked="allSumStaus"
        @change="changeSumStausF(!allSumStaus)"/> 
  		</header>
</template>

<script>
export default {
  name: 'TodoHeader',
  props: ['addItem', 'allSumStaus', 'changeSumStaus', 'dataListLength'],
  methods: {
    addItemF(e) {
      let val = e.target.value.trim();
      if (val != '') {
        this.addItem(val);
        e.target.value = '';
      }
    },
    changeSumStausF(status) {
      this.changeSumStaus(status);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
